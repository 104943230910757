import { useEffect, useState } from "react"
import { apiUser } from "api"
import CustomButton from "./CustomButton"
import { iconHide, iconShow, iconEmail, iconPassword } from "lib/image"
import { setCookieAuth } from "lib/cookie"

const LoginForm = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)

  const onChange = (v) => {
    setError("")
    setUsername(v)
  }

  const onFinish = async (e) => {
    e.preventDefault()
    let res = {}
    try {
      setLoading(true)
      res = await apiUser.login({
        username,
        password
      })

      const { access_token, refresh_token } = res?.data
      let auth = {
        access_token,
        refresh_token
      }
      setCookieAuth(auth)
      window.location.reload()
    } catch (error) {
      const { error_message } = error
      if (error_message && Array.isArray(error_message) && error_message.length > 0) {
        setError(error_message[0])
      } else {
        setError("Something went wrong!")
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (error) setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password])

  return (
    <section>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
                  Email
                </label>
                <input
                  onChange={(e) => onChange(e.target.value)}
                  onFocus={(e) => setError("")}
                  value={username}
                  type="email"
                  name="email"
                  id="email"
                  className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
                  placeholder="Enter email"
                  required
                />
                <span className="relative float-left left-2 -top-[34px]">{iconEmail}</span>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-[#000000A6]"
                >
                  Password
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={(e) => setError("")}
                  value={password}
                  type={showPass ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
                  required
                />
                <span className="relative float-left left-1 -top-[35px]">{iconPassword}</span>
                {password.length > 0 && (
                  <span
                    className="field-icon cursor-pointer"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? iconShow : iconHide}
                  </span>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                      required=""
                    />
                  </div>
                  <div className="ml-3">
                    <label htmlFor="remember" className="text-[14px] text-[#000000E0] font-[400]">
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
              {error !== "" && <div className="margin-text-top text-red-500">{error}</div>}
              <CustomButton
                title="Sign In"
                loading={loading}
                disabled={error !== "" ? true : false}
              />
              <div>
                <p className="text-center text-[14px] text-[#000000A6] font-[400]">
                  2023 Organic Mobility Inc. Alright Reserved
                </p>

                <p className="text-center">
                  <a 
                    href="https://organic-mobility.com/privacy-policy" 
                    className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]"
                  >
                    Privacy & Policy
                  </a>
                  <span className="text-[14px] text-[#000000A6] font-[400]"> and </span>
                  <a 
                    href="https://organic-mobility.com/terms" 
                    className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]"
                  >
                    Terms of Service
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginForm
