/* eslint-disable jsx-a11y/anchor-is-valid */
import { apiUser, apiApps } from "api"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { getCookieAuth } from "lib/cookie"
import { useStore } from "components/ui"
import { setEnums } from "lib/localstorage"
// import { isEmailValid } from "lib/validate"
import { ENUMS_CONFIG } from "lib/Const"
import { changeLanguage } from "utils/i18n"
import "./index.css"
import { apiAms } from "api"
import LoginForm from "./LoginForm"
import SuspenseComponent from "components/Router/SuspenseComponent"

const Login = () => {
  const navigate = useNavigate()
  const { setAuthStore } = useStore()
  const { access_token } = getCookieAuth()
  const local = window.location.href.includes("local")

  const callListApi = async () => {
    try {
      const _userInfo = apiUser.getUserInfo()
      const _application = apiApps.getAuthCode()
      const [userInfo, application] = await Promise.all([_userInfo, _application])

      localStorage.setItem("application", JSON.stringify(application?.data))
      const orgName = userInfo?.data?.organization?.name
      const defaultLanguage = userInfo?.data?.language || "en"

      // Prepare the base data object to be stored locally
      let baseData = {
        user: userInfo?.data,
        orgName: orgName,
        permission: [],
        access_token: access_token
      }
      // Set local enums and base data
      setEnums(ENUMS_CONFIG)
      await changeLanguage(defaultLanguage)
      // Set authentication data in the store
      setAuthStore(baseData)
      handleSetDataTranslateErrorMsg()
      // Redirect to the dashboard page
      navigate("/chauffeur-connect")
    } catch (error) {}
  }

  async function handleSetDataTranslateErrorMsg() {
    try {
      const { data } = await apiAms.getTranslateErrorMessage()
      const errorMsg =
        data &&
        data.length &&
        data.map((item) => ({
          error_code: item.error_code,
          content: item.content,
          language_code: item.language_code
        }))
      errorMsg && localStorage.setItem("errorMsg", JSON.stringify(errorMsg))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (access_token) {
      callListApi()
    } else {
      if (!local) {
        window.location.href = `${process.env.REACT_APP_SSO_URL}`
      }
    }
  }, [access_token])

  return <div>{local ? <LoginForm /> : <SuspenseComponent />}</div>
}

export default Login
